import React from 'react';

function PageNotFound() {
  return (
    <div className="Router">
    
    </div>
  );
}

export default PageNotFound;
